import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { clearAccountInfoAction, loadAccountInfoAction } from '../../../actions/accountActions';
import LoadingIndicator from '../../ui/LoadingIndicator';

class DashboardMasterAccountInfo extends Component {

  constructor(props) {

    super(props);

    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
  }

  componentDidMount() {
    // Dispatch an action to load the account info from the REST API server
    this.props.dispatch(loadAccountInfoAction(this.props.accountNo));
  }

  componentWillUnmount() {
    // Dispatch an action to clear the account info (so it doesn't appear on the screen the next time the component is loaded)
    this.props.dispatch(clearAccountInfoAction());
  }

  render() {

    return (
      <div className="card card-dashcard">

        <div className="card-header">
          <h2>Master Account Info<div className="pull-right"><span className="glyphicon glyphicon-question-sign" style={{fontSize: "20px"}} data-toggle="tooltip" data-placement="left" title="" data-original-title="This is the contact information that is on file for your account. It is not possible for you to edit this information online. If you wish to change it, you must contact customer support."></span></div></h2>
        </div>

        <div className="card-body">

          {!this.props.accountInfo && <div className="py-5"><LoadingIndicator /></div>}

          {this.props.accountInfo &&
            <>
              <div className="row">

                <div className="col-6">
                    <p className="text-start"><strong>Account ID</strong><br />
                    {this.props.accountInfo.accountNo}
                    </p>
                </div>

                <div className="col-6">
                    <p className="text-start"><strong>Status</strong><br />
                    {this.props.accountInfo.customerStatusCode === 'A' && <>Active</>}
                    {this.props.accountInfo.customerStatusCode === 'F' && <span className="badge badge-danger">Inactive</span>}
                    {this.props.accountInfo.customerStatusCode === 'N' && <span className="badge badge-danger">Never Installed</span>}
                    </p>
                </div>

              </div>


              <p className="text-start"><strong>Name</strong><br />
              {this.props.accountInfo.customerName}
              </p>

              <p className="text-start"><strong>Address</strong><br />
              {this.props.accountInfo.address1}
              {this.props.accountInfo.address2 !== '' && <><br />{this.props.accountInfo.address2}</>}
              {this.props.accountInfo.address3 !== '' && <><br />{this.props.accountInfo.address3}</>}
              {this.props.accountInfo.address4 !== '' && <><br />{this.props.accountInfo.address4}</>}
              </p>

              {this.props.accountInfo.homePhone !== '' &&
              <>
                  <p className="text-start"><strong>Home Phone</strong><br />
                  {this.props.accountInfo.homePhone}
                  </p>
              </>}

              {this.props.accountInfo.businessPhone !== '' &&
              <>
                  <p className="text-start"><strong>Business Phone</strong><br />
                  {this.props.accountInfo.businessPhone}
                  </p>
              </>}

              {this.props.accountInfo.otherPhone !== '' &&
              <>
                  <p className="text-start"><strong>Other Phone</strong><br />
                  {this.props.accountInfo.otherPhone}
                  </p>
              </>}

              <p className="mt-2"><em>To change the above information the account owner must contact customer support at <a className="d-inline-block" target="_blank" rel="noopener noreferrer" href="tel:+13017975000">301-797-5000</a>.</em></p>

              {this.props.hoh === '1' && (this.props.authAccountNo === this.props.accountInfo.accountNo) &&
                <div className="text-center my-1">
                  <a className="btn btn-default" target="_blank" rel="noopener noreferrer" href="https://billpay.myactv.net/ebpp/WSC/SSOAccountOverview" title="This link will take you to our billing system.">View / Pay Bill &nbsp; <i className="fa fa-external-link" aria-hidden="true"></i></a>
                </div>
              }
            </>
          }

        </div>

      </div>
    )

  }

}

function mapStateToProps(state) {
  return {
    accountInfo: state.account.info,
    authAccountNo: state.auth.accountNo,
    hoh: state.auth.hoh
  }
}

export default withRouter(connect(mapStateToProps)(DashboardMasterAccountInfo));
